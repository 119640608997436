/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* fontface.less 2013-1-16 *******/
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #737373;
  color: #737373;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #262626 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #b3b3b3;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: 700;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: 700;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #89ae3b;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #85b423;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #85b423;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #85b423;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #85b423;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 765px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #b3b3b3;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: 700;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #b3b3b3;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(133, 180, 35, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  background-color: transparent;
  color: #262626;
  font-family: 'niveau-grotesk', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.06em;
}
#desk {
  *zoom: 1;
}
#desk:before,
#desk:after {
  display: table;
  content: '';
}
#desk:after {
  clear: both;
}
.cb-home {
  width: 130px;
  height: 60px;
  background: url(/images/cb-home.svg) no-repeat 50% 120%;
  background-size: 45%;
  transition: background-position 0.1s ease-out, height 0.218s;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
.logo {
  width: 130px;
  height: auto;
  transition: opacity 0.218s;
}
.cb-page-title {
  float: left;
  width: 100%;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 1;
}
.header-desk {
  padding: 26px 0;
  transition: padding 0.218s;
}
.justify {
  text-align: justify;
  line-height: 0;
}
.justify:after {
  content: '';
  width: 90%;
  display: inline-block;
}
.togglenavigation,
.cb-home,
.scroll-to-maps {
  display: inline-block;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}
.togglenavigation {
  width: 24px;
  height: 24px;
  background: url(/images/togglenavigation__lines.svg) no-repeat 50%;
  cursor: pointer;
}
.scroll-to-maps {
  width: 24px;
  height: 24px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background: url(/images/icon-place.svg) no-repeat 50%;
}
.cb-elastic-content-small {
  padding: 0;
}
.cb-elastic-content-small .cb-home {
  transition-duration: 0.218s, 0.218s;
  background-position: 50%;
  height: 48px;
}
.cb-elastic-content-small .logo {
  opacity: 0;
}
.cb-toggle-target-active .cb-home,
.cb-toggle-target-active .scroll-to-maps {
  display: none;
}
.cb-toggle-target-active .togglenavigation {
  background-image: url(/images/togglenavigation-close--square.svg);
}
.togglenavigation--close {
  margin-top: 43px;
}
.cb-elastic-content-small .togglenavigation--close {
  margin-top: 17px;
}
/*
3 × .mood
*/
.cb-mood {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
}
.cb-page-color-c3 .cb-mood--around,
.cb-page-color-c3 .cb-mood--bottom,
.cb-page-color-c4 .cb-mood--top,
.cb-page-color-c4 .cb-mood--around,
.cb-page-layout1 .cb-mood--around,
.cb-page-layout2 .cb-mood--top,
.cb-page-layout2 .cb-mood--bottom {
  background-image: none !important;
}
.cb-page-color-c1 .cb-mood--top,
.cb-page-color-c1 .cb-mood--bottom,
.cb-page-color-c2 .cb-mood--top,
.cb-page-color-c2 .cb-mood--bottom,
.cb-page-color-c3 .cb-mood--bottom,
.cb-page-color-c4 .cb-mood--top,
.cb-page-layout2 .cb-mood--top,
.cb-page-layout2 .cb-mood--bottom {
  display: none;
}
.cb-page-color-c2 .cb-mood--around {
  background: #eef3f0;
}
.cb-page-color-c2.cb-page-first.cb-page-last .cb-mood--around {
  /* color for Gutschein */
  background: #dde6e0;
}
.cb-page-color-c3 .mood--top {
  height: 200px;
  background-image: inherit !important;
}
.cb-multimood,
.cb-expo,
.cb-slides,
.slide,
.slide > a {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.cb-slides,
.slide {
  max-width: none !important;
  max-height: none !important;
  height: 100% !important;
}
.slide .cb-portrait,
.slide .cb-landscape {
  width: 100%;
  max-width: none !important;
  max-height: none !important;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover; object-position: 50% 50%;';
}
.cb-expo > .link {
  display: none;
}
#view .pict,
#view .cb-mood--above {
  filter: grayscale(100%);
  transition: filter 0.3s 0.6s ease-out;
}
#view .cb-page-selected .pict,
#view .cb-page-selected .cb-mood--above {
  filter: grayscale(0);
}
.reserve-desk {
  bottom: 70px;
  left: 0;
  right: 0;
  position: fixed;
  height: 0;
  font-size: 12px;
  font-size: 1.2rem;
  margin-top: -1.3em;
}
.reservationActive .reserve-desk {
  display: none;
}
.cb-page-layout1.cb-page-first.cb-page-color-c3 .reserve-desk {
  display: block;
}
.service_reserve {
  font-weight: 700 !important;
  line-height: 2.66666667 !important;
  font-family: 'niveau-grotesk', sans-serif !important;
  display: inline-block !important;
  padding: 0 2em !important;
  text-align: center !important;
  letter-spacing: 0.3em !important;
  text-transform: uppercase !important;
  color: #fff !important;
  border-radius: 16px !important;
  background: #e63348 !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.5) !important;
  font-size: 12px !important;
  font-size: 1.2rem !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: box-shadow 0.218s;
  text-shadow: none !important;
}
.service_reserve:hover,
.service_reserve:focus {
  background: #e7384c !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.46) !important;
}
.service_reserve:active {
  background: #e4253c !important;
}
.footer {
  color: #fff;
  padding-top: 52px;
  padding-bottom: 60px;
  letter-spacing: 0.08em;
  background: #a6b421;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.footer ::-moz-selection {
  /* has to stay separate */
  background: #444;
}
.footer ::selection {
  /* has to stay separate */
  background: #444;
}
.footer a {
  color: #fff;
}
.footer a:hover,
.footer a:focus {
  color: #567417;
}
.footer-title {
  /*
  h2
  */
  letter-spacing: 0.1em;
  font-weight: 500;
  font-size: 18px;
  font-size: 1.8rem;
}
.footer-title:after {
  width: 44px;
  display: block;
  content: '';
  height: 4px;
  background: #fff;
  margin-top: 11px;
}
.vcard__address {
  float: left;
  width: 100%;
  padding-bottom: 3.3em;
}
.fn,
.company,
.adr,
.street-address,
.vcardemail,
.openday {
  display: block;
}
a.tel {
  margin-top: 1em;
  display: inline-block;
}
.fn,
.footer-title + .hours {
  margin-top: 3em;
}
.opentimes {
  font-weight: 400;
}
.openday--sa,
.somo {
  display: block;
  margin-top: 1.5em;
}
.ferien {
  font-weight: 400;
}
.ferien:first-line {
  font-weight: 500;
}
#social {
  float: left;
  margin-top: 20px;
}
#social .meta {
  float: left;
  width: 20px;
  height: 20px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  margin-right: 20px;
  background-color: #fff;
  mask-size: 100% 100%;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-color: #567417;
}
#social .meta.service_facebook {
  mask-image: url(/images/social-facebook.svg);
}
#social .meta.service_instagram {
  mask-image: url(/images/social-instagram.svg);
}
#cmsbox {
  float: left;
  width: 100%;
  font-size: 12px;
  font-size: 1.2rem;
  text-transform: lowercase;
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.3);
}
#cmsbox > .meta {
  color: rgba(255, 255, 255, 0.4);
}
#cmsbox > .meta:hover,
#cmsbox > .meta:focus {
  color: rgba(255, 255, 255, 0.6);
}
.navigation {
  display: none;
  margin-top: 100px;
}
.navigation {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 100%;
  left: 0;
  overflow: scroll;
  margin-top: 0;
  padding-bottom: 60px;
}
.cb-toggle-target-active .navigation {
  display: block;
  bottom: 0;
  background: #e63348;
}
.navi,
.navi > .item {
  float: left;
}
.menu {
  display: block;
  -webkit-tap-highlight-color: transparent;
}
.navi,
.navi > .item {
  width: 100%;
}
.menu {
  font-size: 24px;
  font-size: 2.4rem;
  color: #fff;
  line-height: 1.1;
  padding: 0.45em 1em 0.45em 0;
}
h1,
.unit h2 {
  font-weight: 700;
  line-height: 1.2;
}
h1 {
  font-weight: normal;
  line-height: 2;
  padding-bottom: 3px;
  position: relative;
  letter-spacing: 0.08em;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
}
h1:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 4px;
  content: '';
  background: #e63348;
}
#view .cb-page-layout2 .cb-page-title,
#view .cb-pages .cb-page-first .cb-page-title {
  display: none;
}
.unit h2 {
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.08em;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 56px;
  font-size: 5.6rem;
}
h3 {
  font-weight: normal;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
}
.pale {
  color: #737373;
  font-size: 12px;
  font-size: 1.2rem;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #97bf44;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #a2c657;
}
.part.link {
  background: none !important;
}
.part.link > .open {
  margin-left: 0;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.fold,
.seam {
  padding: 1.2em 0 1.05em;
  position: relative;
  margin-bottom: 0;
}
.fold:before,
.fold:after,
.seam:before,
.seam:after {
  content: '';
  height: 1px;
  background: rgba(161, 175, 31, 0.2);
  position: absolute;
}
.fold:before,
.seam:before {
  top: 0;
}
.fold:after,
.seam:after {
  bottom: 0;
}
.fold .body,
.seam .body {
  margin: 0;
}
.body > .more {
  padding-top: 18px;
}
.fold + .fold,
.seam + .seam {
  margin-top: 0;
}
.fold + .fold:before,
.seam + .seam:before {
  display: none;
}
.fold h2,
.seam h2 {
  line-height: 1.2;
  font-weight: normal;
  letter-spacing: 0.05em;
  font-size: 18px;
  font-size: 1.8rem;
}
.fold h2 > strong,
.seam h2 {
  font-weight: normal;
  display: inline-block;
  min-width: 9em;
  letter-spacing: 0.08em;
  color: #85b423;
}
.fold .ctrl {
  text-align: right;
  position: absolute;
  top: 1em;
  line-height: 0;
}
#view .fold .ctrl {
  width: 100%;
}
.fold .ctrl > .link {
  display: inline-block;
  width: 50px;
  height: 24px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  cursor: pointer;
  background: url(/images/fold-more--square.svg) no-repeat 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}
#view .fold .ctrl > .more {
  width: 100%;
}
.fold .ctrl > .less {
  width: 50px;
  background-image: url(/images/fold-less--square.svg);
}
#view .seam .body:empty {
  margin-top: 0;
  margin-bottom: 0;
  min-height: 0;
}
.cb-page-layout1 .unit h2 {
  margin-bottom: 12px;
}
.cb-page-layout1 .fold h2 {
  margin-bottom: 0;
}
.cb-page-layout2 .unit {
  background: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  color: #333333;
}
.cb-page-layout2 .unit h2 {
  font-weight: normal;
  line-height: 2;
  padding-bottom: 3px;
  position: relative;
  letter-spacing: 0.08em;
  color: #333333;
  font-size: 18px;
  font-size: 1.8rem;
}
.cb-page-layout2 .unit h2:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 4px;
  content: '';
  background: #e63348;
}
.cb-page-layout2 .unit h3 {
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.08em;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 56px;
  font-size: 5.6rem;
}
.cb-page-layout2 .flat {
  color: #fff;
  background: #645255;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.cb-page-layout2 .flat h2 {
  color: #fff;
}
.cb-page-layout2 div.link,
.cb-page-layout2 div.load,
.form .cb-form-send {
  text-align: center;
  margin-top: 3em;
  background: none !important;
}
.cb-page-layout2 div.link > .open,
.cb-page-layout2 a.load,
.form .cb-form-send {
  font-size: 11px;
  font-size: 1.1rem;
  display: inline-block;
  margin: 0 auto;
  padding: 0 1em;
  font-weight: 700;
  line-height: 2.66666667;
  border-radius: 16px;
  text-transform: uppercase;
  letter-spacing: 0.23em;
  border: 1px solid #85b423;
  -webkit-tap-highlight-color: transparent;
}
.cb-page-layout2 div.link > .open:hover,
.cb-page-layout2 a.load:hover,
.form .cb-form-send:hover,
.cb-page-layout2 div.link > .open:focus,
.cb-page-layout2 a.load:focus,
.form .cb-form-send:focus {
  color: #fff;
  background: #cddf2a !important;
  border-color: #cddf2a;
  text-shadow: 0 0 2px rgba(101, 137, 27, 0.3);
}
.cb-page-layout2 div.link > .open:active,
.cb-page-layout2 a.load:active,
.form .cb-form-send:active {
  background: #7c8719 !important;
  border-color: #7c8719;
}
.form .body.body {
  margin-top: 2em;
}
.form .lead {
  margin-top: 3em;
  margin-bottom: 1em !important;
}
.form .pale {
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 1.6em;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
}
.form input.text,
.form textarea {
  box-shadow: none !important;
  border-color: #fff !important;
  border-radius: 0;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}
.form .foot.foot {
  text-align: center;
  margin-top: 20px;
}
.form .cb-form-send {
  padding-right: 3.5em;
  padding-left: 3.5em;
  text-shadow: none;
  color: #85b423;
  margin-top: 3em;
}
.grow {
  text-transform: none !important;
}
/******* forms.less 2016-01-05 *******/
/* Changelog

2016-01-05
added Calendar (partial copy of manage.css)

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'niveau-grotesk', sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.11428571em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  font-weight: normal;
  font-family: 'niveau-grotesk', sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  padding: 0.11428571em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #759f1f #567417 #567417 #759f1f;
  border-radius: 2px;
  background-color: #85b423;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(70, 95, 18, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #759f1f #567417 #567417 #759f1f;
  background-color: #8ec125;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #759f1f #567417 #567417 #759f1f;
  background-color: #65891b;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  line-height: 1.5;
  position: absolute;
  z-index: 100;
  text-align: right;
  border: 3px solid #666;
  background: #999;
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  font-weight: 700;
  text-align: center;
  color: #eee;
}
.calendar tbody {
  color: #999;
  background-color: #eee;
}
td.show {
  font-weight: 700;
  color: #fff;
  background: #7be;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.calendar th > a:hover,
.calendar td > a:hover,
.calendar th > a:focus,
.calendar td > a:focus {
  color: #fff;
  background: #ccc;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  width: 91.11111111%;
  max-width: 480px;
}
.cb-page-title {
  padding-bottom: 17px;
}
.cb-page-color-c3 .cb-mood--top {
  height: 596px;
}
.cb-page-color-c4 .cb-mood--bottom {
  height: 848px;
}
.maincontent {
  padding-top: 49px;
  padding-bottom: 49px;
}
.cb-page-color-c3 + .cb-page-color-c1 .maincontent {
  padding-top: 20px;
}
.cb-page-layout2 .maincontent {
  padding-top: 48px;
  padding-bottom: 48px;
}
.reserve-desk {
  text-align: center;
}
.sub1 {
  margin-top: 40px;
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main h2,
.main .foot {
  margin-right: 2.3255814%;
  margin-left: 2.3255814%;
}
.main .part,
.main > .grid table {
  margin-right: 2.3255814%;
  margin-left: 2.3255814%;
  width: 95.34883721%;
}
.main .tiny {
  width: 45.34883721%;
}
.main > .slim .part,
.main > .slim.grid table {
  width: 95.34883721%;
}
.cb-page-layout2 .area {
  width: 100%;
}
.cb-page-layout2 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .area h2,
.cb-page-layout2 .area .foot {
  margin-right: 12.19512195%;
  margin-left: 12.19512195%;
}
.cb-page-layout2 .area .part,
.cb-page-layout2 .area > .grid table {
  margin-right: 12.19512195%;
  margin-left: 12.19512195%;
  width: 75.6097561%;
}
.cb-page-layout2 .area > .slim .part,
.cb-page-layout2 .area > .slim.grid table {
  width: 75.6097561%;
}
.cb-page-layout1 .main {
  width: 104.87804878%;
  margin-left: -2.43902439%;
}
#edit .side {
  padding-top: 2px;
  border-top: 2px dashed #ccc;
}
.fold:before,
.fold:after,
.seam:before,
.seam:after {
  left: 2.43902439%;
  right: 2.43902439%;
}
.fold h2 > strong {
  display: block;
  max-width: calc(100% - 30px);
  padding-bottom: 0.2em;
}
.fold .ctrl {
  right: 2.43902439%;
}
.cb-page-layout2 .unit {
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 30px 0 20px;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */